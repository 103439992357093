// RoadmapView.js
import React, { useEffect, useState } from 'react';

function RoadmapView() {
    const [data, setData] = useState(null);

    useEffect(() => {
        const api_key = process.env.REACT_APP_API_KEY;
        fetch('https://agilitytools.co.uk:5001/dataps/', {
            headers: {
                'X-API-KEY': api_key  // Including the API key in the request header
            }
        })
            .then(response => response.json())
            .then(jsonData => {
                setData(jsonData);
            })
            .catch(error => {
                console.error("Error fetching data: ", error);
            });
    }, []);

    return (
        <div className="App">
            {data && data.map((rm, index) => (
                <div key={index} className="roadmap">
                    <div className="roadmap-title">
                        <h1>{rm.cr248_roadmapname}</h1>
                    </div>
                    {rm.L1s && rm.L1s.map((L1, indexl1) => (
                        <div key={indexl1} className="l1">
                            <h2>{L1.cr248_name}</h2>
                            <div className="l1">

                                {L1.L2s && L1.L2s.map((L2, indexl2) => (
                                    <div key={indexl2} className="l1">
                                        <div className="l1">
                                            <div className="l1">
                                                <h2>{L2.cr248_name}</h2>
                                            </div>
                                            <div className="l1">
                                            {L2.Items && L2.Items.map((Item, i) => (
                                                <a href={`https://orgca5b7aae.crm4.dynamics.com/main.aspx?appid=754d6e3c-122b-ee11-bdf4-6045bd8c5fdb&pagetype=entityrecord&etn=cr248_item&id=${Item.cr248_itemid}`} key={Item.cr248_itemid} className={"no-link"}>
                                                    <div className="l1">
                                                    <div key={i} className="l1">
                                                        <h3>{Item.cr248_itemname}</h3>

                                                        <div className="content" dangerouslySetInnerHTML={{__html: Item.cr248_itemdescription}}></div>
                                                    </div>
                                                    </div>
                                                </a>
                                            ))}

                                        </div>

                                    </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    ))}
                </div>
            ))}

        </div>
    );

}

export default RoadmapView;
