import React, {useEffect, useState} from 'react';

function ProblemStatementsd() {
    const [data, setData] = useState(null);

    useEffect(() => {
        const api_key = process.env.REACT_APP_API_KEY;
        fetch('https://agilitytools.co.uk:5001/dataps/', {
            headers: {
                'X-API-KEY': api_key  // Including the API key in the request header
            }
        })
            .then(response => response.json())
            .then(jsonData => {
                setData(jsonData);
            })
            .catch(error => {
                console.error("Error fetching data: ", error);
            });
    }, []);

    console.log(data);

    return (
        <div className="App">
            {data && data.map((rm, index) => (
                <div key={index} className="roadmap">
                    <div className="roadmap-title">
                        <h1>{rm.cr248_roadmapname}</h1>
                    </div>
                    {rm.L1s && rm.L1s.map((L1, indexl1) => (
                        <div className={'l2-container'}>
                            <div key={indexl1} className="l2-item-container">
                                <a href={`https://orgca5b7aae.crm4.dynamics.com/main.aspx?appid=754d6e3c-122b-ee11-bdf4-6045bd8c5fdb&pagetype=entityrecord&etn=cr248_problemstatementlevelone&id=${L1.cr248_problemstatementleveloneid}`}
                                   key={indexl1} className={"no-link"}>
                                    <div className="l2-ps">
                                        <div>
                                        <h3>{L1.cr248_name}</h3>
                                        <div className={"force-new-line"}></div>
                                        <p>{L1.cr248_problemstatement}</p>
                                        </div>
                                    </div>
                                </a>
                                <div className="item-container">
                                    {L1.L2s && L1.L2s.map((L2, indexl2) => (
                                        <div key={indexl2} className="item-container">
                                            <div className="l2-cont">
                                                <a href={`https://orgca5b7aae.crm4.dynamics.com/main.aspx?appid=754d6e3c-122b-ee11-bdf4-6045bd8c5fdb&pagetype=entityrecord&etn=cr248_problemstatementleveltwo&id=${L2.cr248_problemstatementleveltwoid}`}
                                                   key={indexl1} className={"no-link"}>
                                                    <div className="item">

                                                        <div>
                                                            <h3 className={"content"}>{L2.cr248_name}</h3>
                                                            <div className={"force-new-line"}></div>
                                                            <p>{L2.cr248_problemstatement}</p>

                                                        </div>

                                                    </div>
                                                </a>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default ProblemStatementsd;
