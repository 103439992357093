// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import RoadmapView from './RoadmapView';
import AnotherView from './AnotherView';
import ProblemStatements from "./problemStatements";
import ProblemStatementsd from "./problemStatementsd";
import ProblemStatementsI from "./problemStatementsI";
import ProblemStatementsId from "./problemStatementsId";
import './App.css';
import { useAuth0 } from "@auth0/auth0-react";


function App() {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

    useEffect(() => {
        // Check if the user is authenticated and store the state in localStorage
        if (isAuthenticated) {
            localStorage.setItem('isAuthenticated', 'true');
        } else {
            localStorage.removeItem('isAuthenticated');
        }
    }, [isAuthenticated]);

    return (
        <Router>
            {isAuthenticated || localStorage.getItem('isAuthenticated') === 'true' ? (
                <div>

                <nav>
                    <ul>
                        <li>
                            <Link to="/">Roadmap View</Link>
                        </li>
                        <li>
                            <Link to="/another">Position View</Link>
                        </li>
                        <li>
                            <Link to="/problem">Problem Statments Only</Link>
                        </li>

                        <li>
                            <Link to="/problemd">Problem Statments Only Details</Link>
                        </li>
                        <li>
                            <Link to="/problemi">Problem Statments and items</Link>
                        </li>
                        <li>
                            <Link to="/problemid">Problem Statments and items with details</Link>
                        </li>
                    </ul>
                </nav>

                <Routes>
                    <Route path="/" element={<RoadmapView />} />
                    <Route path="/another" element={<AnotherView />} />
                    <Route path="/problem" element={<ProblemStatements />} />
                    <Route path="/problemd" element={<ProblemStatementsd />} />
                    <Route path="/problemi" element={<ProblemStatementsI />} />
                    <Route path="/problemid" element={<ProblemStatementsId />} />

                </Routes>
                    <button onClick={() => logout()}>Log Out</button>

            </div>
            ) : (
                <button onClick={() => loginWithRedirect()}>Log In</button>
            )}
        </Router>
    );
}

export default App;
