import React, { useEffect, useState } from 'react';

function AnotherView() {
    const [data, setData] = useState(null);

    useEffect(() => {
        const api_key = process.env.REACT_APP_API_KEY;
        fetch('https://agilitytools.co.uk:5001/data/',{
            headers: {
                'X-API-KEY': api_key  // Including the API key in the request header
            }
        })
            .then(response => response.json())
            .then(jsonData => {
                setData(jsonData);
            })
            .catch(error => {
                console.error("Error fetching data: ", error);
            });
    }, []);

    console.log(data);

    return (
        <div className="App">
            {data && data.map((rm, index) => (
                <div key={index} className="roadmap">
                    <div className="roadmap-title">
                        <h1>{rm.cr248_roadmapname}</h1>
                    </div>
                    {rm.L1s && rm.L1s.map((L1, indexl1) => (
                        <div key={indexl1} className="l1">
                            <h2>{L1.cr248_l1name}</h2>
                            <div className="l2-container">
                                {L1.L2s && L1.L2s.map((L2, indexl2) => (
                                    <div key={indexl2} className="l2-item-container">
                                        <div className="l2-cont">
                                            <div className="l2">
                                                <h3>{L2.cr248_l2name}</h3>
                                            </div>
                                        </div>
                                        <div className="item-container">
                                            {L2.ItemsSP && L2.ItemsSP.map((ItemSP, ih) => (
                                                <div key={ih} className="item-container-third">
                                                    <div className="itemh">
                                                        {ItemSP["cr248_strategicposition@OData.Community.Display.V1.FormattedValue"]}
                                                    </div>
                                                    <div className="force-new-line"></div>

                                                    <div className={"item-container"}>
                                                    {ItemSP.aggt && ItemSP.aggt.map((aggt, i) => (
                                                        <a href={`https://orgca5b7aae.crm4.dynamics.com/main.aspx?appid=754d6e3c-122b-ee11-bdf4-6045bd8c5fdb&pagetype=entityrecord&etn=cr248_item&id=${aggt.id}`} key={aggt.id} className={"no-link"}>
                                                            <div key={i} className="item">
                                                                {aggt.name}
                                                            </div>
                                                        </a>
                                                    ))}
                                                </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default AnotherView;
